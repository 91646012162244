
import { defineComponent, ref } from "vue";
import Multiselect from "@vueform/multiselect";
import CodeHighlighter2 from "@/components/highlighters/CodeHighlighter2.vue";

export default defineComponent({
  name: "example-7",
  components: {
    Multiselect,
    CodeHighlighter2
  },
  setup() {
    const example7 = ref({
      mode: "tags",
      value: [],
      placeholder: "Select employees",
      search: true,
      trackBy: "name",
      label: "name",
      options: [
        {
          value: "judy",
          name: "Judy",
          image: "https://randomuser.me/api/portraits/med/women/1.jpg"
        },
        {
          value: "jane",
          name: "Jane",
          image: "https://randomuser.me/api/portraits/med/women/2.jpg"
        },
        {
          value: "john",
          name: "John",
          image: "https://randomuser.me/api/portraits/med/men/1.jpg"
        },
        {
          value: "joe",
          name: "Joe",
          image: "https://randomuser.me/api/portraits/med/men/2.jpg"
        }
      ]
    });

    const htmlCode = `<Multiselect
      v-model="example7.value"
      v-bind="example7"
    >
      <template v-slot:tag="{ option, handleTagRemove, disabled }">
        <div class="multiselect-tag is-user">
          <img :src="option.image">
          {{ option.name }}
          <i
            v-if="!disabled"
            @click.prevent
            @mousedown.prevent.stop="handleTagRemove(option, $event)"
          ></i>
        </div>
      </template>

      <template v-slot:option="{ option }">
        <img class="user-image" :src="option.image"> {{ option.name }}
      </template>
    </Multiselect>`;

    const jsCode = `const example7 = ref({
      mode: "tags",
      value: [],
      placeholder: "Select employees",
      search: true,
      trackBy: "name",
      label: "name",
      options: [
        {
          value: "judy",
          name: "Judy",
          image: "https://randomuser.me/api/portraits/med/women/1.jpg"
        },
        {
          value: "jane",
          name: "Jane",
          image: "https://randomuser.me/api/portraits/med/women/2.jpg"
        },
        {
          value: "john",
          name: "John",
          image: "https://randomuser.me/api/portraits/med/men/1.jpg"
        },
        {
          value: "joe",
          name: "Joe",
          image: "https://randomuser.me/api/portraits/med/men/2.jpg"
        }
      ]
    });`;

    return {
      example7,
      htmlCode,
      jsCode
    };
  }
});
