<template>
  <!--begin::Section-->
  <div class="pb-10">
    <!--begin::Heading-->
    <h1 class="anchor fw-bolder mb-5" id="overview">
      <a href="#overview"></a>
      Overview
    </h1>
    <!--end::Heading-->

    <!--begin::Block-->
    <div class="py-5">
      <a href="https://github.com/vueform/multiselect" class="fw-bold"
        >Vue 3 multiselect</a
      >
      component with single select, multiselect and tagging options.
    </div>
    <!--end::Block-->
  </div>
  <!--end::Section-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "overview",
  components: {}
});
</script>
